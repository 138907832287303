import {RouteObject, useRouteError} from 'react-router';

import AllLocation from '~/pages/allLocations';
import MainPage from '~/pages/home/MainPage';
import Page404 from '~/pages/commonPages/Page404';
import PrivateRoute from '~/components/system/PrivateRoute';
import {
  AccountChangeEmail,
  AccountConfirmEmail,
  BuyUpgrades,
  AccountChangePassword,
  AdEditThumbnail,
  AdDuplicate,
  AdEdit,
  Account,
  MyAds,
  AdChangeLocation,
  AccountChangeSettings,
  AccountChatSettings,
  AccountChangeUsername,
  ChangeToAgency,
  ChangeToIndividual,
  AccountPayments,
  AccountClose,
  AdEditStep1,
  AdEditStep2,
  AdEditConfirm,
  BuyTopups,
  AdVerificationRedirect,
  UnverifiedInfo,
} from '~/pages/account';
import {
  AuthResetPassword,
  AuthSignIn,
  AuthSignUp,
  AuthSignupConfirm,
  Security2FA,
} from '~/pages/auth';
import {PaymentForm, PaymentRecharge, PaymentStatus} from '~/pages/payments';
import {
  ContactUs,
  Faq,
  FaqAdvertiser,
  FaqEscortAgency,
  FaqIncident,
  FaqLawEnforcement,
  FaqRobbed,
  PrivacyPolicy,
  TermsOfService,
} from '~/pages/commonPages';
import {Chat, ChatList} from '~/pages/chat';
import LocationRoute from '~/pages/location/route';
import ScrollToTop from '~/components/system/ScrollToTop';
import App from '~/components/App';

const Page404Item = {
  path: '*',
  element: <Page404 />,
};

const routes: RouteObject[] = [
  {
    index: true,
    element: <MainPage />,
  },
  {
    path: 'all-locations',
    element: <AllLocation />,
  },

  // Account Pages
  {
    path: 'account',
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        ),
      },
      {
        path: 'change-email',
        element: (
          <PrivateRoute>
            <AccountChangeEmail />
          </PrivateRoute>
        ),
      },
      {
        path: 'confirm-email',
        element: (
          <PrivateRoute>
            <AccountConfirmEmail />
          </PrivateRoute>
        ),
      },
      {
        path: 'change-password',
        element: (
          <PrivateRoute>
            <AccountChangePassword />
          </PrivateRoute>
        ),
      },
      {
        path: 'change-settings',
        element: (
          <PrivateRoute>
            <AccountChangeSettings />
          </PrivateRoute>
        ),
      },
      {
        path: 'chat-settings',
        element: (
          <PrivateRoute>
            <AccountChatSettings />
          </PrivateRoute>
        ),
      },
      {
        path: 'change-username',
        element: (
          <PrivateRoute>
            <AccountChangeUsername />
          </PrivateRoute>
        ),
      },
      {
        path: 'change-agency',
        element: (
          <PrivateRoute>
            <ChangeToAgency />
          </PrivateRoute>
        ),
      },
      {
        path: 'change-individual',
        element: (
          <PrivateRoute>
            <ChangeToIndividual />
          </PrivateRoute>
        ),
      },
      {
        path: 'payments',
        element: (
          <PrivateRoute>
            <AccountPayments />
          </PrivateRoute>
        ),
      },
      {
        path: 'close',
        element: (
          <PrivateRoute>
            <AccountClose />
          </PrivateRoute>
        ),
      },
      {
        path: 'confirm',
        element: <AuthSignupConfirm />,
      },
      {
        path: 'reset',
        element: <AuthResetPassword />,
      },
      {
        path: 'signin',
        element: <AuthSignIn />,
      },
      {
        path: 'signup',
        element: <AuthSignUp />,
      },
      {
        path: '2fa',
        element: <Security2FA />,
      },
      {
        path: 'unverified',
        element: <UnverifiedInfo />,
      },
      Page404Item,
    ],
  },

  // profile verification (from e-mail)
  {
    path: 'clad/:escortId/verification',
    element: <AdVerificationRedirect />,
  },

  // Create or edit Advertisement
  {
    path: 'adbuild',
    children: [
      {
        index: true,
        element: <AdEditStep1 />,
      },
      {
        path: 'step2',
        element: <AdEditStep2 />,
      },
      {
        path: 'step3',
        element: <AdEdit action="step3" />,
      },
      {
        path: 'confirm',
        element: (
          <PrivateRoute>
            <AdEditConfirm />
          </PrivateRoute>
        ),
      },
      Page404Item,
    ],
  },

  // Classifieds Pages
  {
    path: 'classifieds',
    children: [
      {
        path: 'topups',
        element: (
          <PrivateRoute>
            <BuyTopups />
          </PrivateRoute>
        ),
      },
      {
        path: 'myposts',
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <MyAds />
              </PrivateRoute>
            ),
          },
          {
            path: ':escortId/:action',
            element: (
              <PrivateRoute>
                <BuyUpgrades />
              </PrivateRoute>
            ),
          },
          {
            path: ':escortId/change_location',
            element: (
              <PrivateRoute>
                <AdChangeLocation />
              </PrivateRoute>
            ),
          },
          {
            path: ':escortId/edit_thumbnail',
            element: (
              <PrivateRoute>
                <AdEditThumbnail />
              </PrivateRoute>
            ),
          },
          {
            path: ':escortId/duplicate',
            element: (
              <PrivateRoute>
                <AdDuplicate />
              </PrivateRoute>
            ),
          },
          {
            path: ':escortId/edit',
            element: <AdEdit action="edit" />,
          },
          {
            path: ':escortId/renew',
            element: <AdEdit action="renew" />,
          },
          {
            path: ':escortId/step3',
            element: <AdEdit action="step3" />,
          },
          Page404Item,
        ],
      },
      Page404Item,
    ],
  },

  // Payment Pages
  {
    path: 'payment',
    children: [
      {
        path: 'pay',
        element: (
          <PrivateRoute>
            <PaymentForm />
          </PrivateRoute>
        ),
      },
      {
        path: 'recharge',
        element: (
          <PrivateRoute>
            <PaymentRecharge />
          </PrivateRoute>
        ),
      },
      {
        path: ':paymentId/status',
        element: (
          <PrivateRoute>
            <PaymentStatus />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },

  // Chat pages
  {
    path: 'chat',
    children: [
      {
        index: true,
        element: <ChatList />,
      },
      {
        path: ':chatId',
        element: <Chat />,
      },
    ],
  },

  // Location Pages
  LocationRoute,

  // Faq Pages
  {
    path: 'faq',
    children: [
      {
        index: true,
        element: <Faq />,
      },
      {
        path: 'advertiser',
        element: <FaqAdvertiser />,
      },
      {
        path: 'escort-agency',
        element: <FaqEscortAgency />,
      },
      {
        path: 'incident',
        element: <FaqIncident />,
      },
      {
        path: 'robbed',
        element: <FaqRobbed />,
      },
      {
        path: 'law-enforcement',
        element: <FaqLawEnforcement />,
      },
      Page404Item,
    ],
  },

  // Static Pages
  {
    path: 'contact_us',
    element: <ContactUs />,
  },
  {
    path: 'privacy_policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'terms_of_service',
    element: <TermsOfService />,
  },

  // 404
  Page404Item,
];

const RootErrorBoundary = () => {
  const error = useRouteError();

  throw error;
};

export const routerConfig: RouteObject[] = [
  {
    path: '/',
    element: (
      <ScrollToTop>
        <App />
      </ScrollToTop>
    ),
    children: routes,
    errorElement: <RootErrorBoundary />,
  },
];
